/*****************************Overlay CSS*******************************/
.fullCover {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.fullCover div {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    object-fit: cover;
    object-position: top;
}

.blend > div:last-child {
    mix-blend-mode: multiply;
}

.overlays {
    filter: blur(50px);
    pointer-events: none;
    background-image: conic-gradient(from 39.11deg at 50% 50%, #5A5AFF 51deg, #5A5AFF 31.2deg, #282828 31.66deg, #F7B500 178.12deg, #5A5AFF 255deg, #5A5AFF 391.2deg);
    background-image: -webkit-conic-gradient(from 39.11deg at 50% 50%, #5A5AFF 51deg, #5A5AFF 31.2deg, #282828 31.66deg, #F7B500 178.12deg, #5A5AFF 255deg, #5A5AFF 391.2deg);
    background-repeat: no-repeat;
    background-size: cover;
}




.bannerTxt {
    display: flex;
    justify-content: end;
    align-items: center;
    height: 100%;
    position: relative;
    z-index: 9;
}

.panels {
    background: linear-gradient(0deg, rgba(40, 40, 40, 0.6) 0%, #282828 100%);
    -webkit-background: linear-gradient(0deg, rgba(40, 40, 40, 0.6) 0%, #282828 100%);
    text-align: center;
    padding: 6px 35px 30px;
    display: flex;
    flex-direction: column;
    width: 295px;
    margin-bottom: 15px;
}

.panels-blue {
    border-top: 2px solid #5A5AFF;
}

.panels-green {
    border-top: 2px solid #466400;
    padding: 6px 0 0px;
    background: linear-gradient(0deg, rgba(40, 40, 40, 0) 0%, #282828 99.92%);
    -webkit-background: linear-gradient(0deg, rgba(40, 40, 40, 0) 0%, #282828 99.92%);
}

.panels-green img {
    margin-left: -10px;
    margin-right: -10px;
}

.panels h2,
h4 {
    font-family: 'Krona One';
    color: #fff;
}

.panels h2 {
    font-size: 25px;
    margin-top: 10px;
    margin-bottom: 0;
}

.panels h4 {
    font-size: 13px;
    text-align: left;
    font-weight: normal;
    margin-bottom: 30px;
}

.panels h6 {
    font-size: 10px;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 1px;
    margin-bottom: 10px;
}

.panels .panels-label h6 {
    margin-top: 5px;
}

.panels .btns {
    color: #5a5aff;
    font-size: 14px;
    background: none;
    border: 1px solid #5a5aff;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    padding: 6px 8px;
    min-width: 85px;
    margin: 0 auto;
    display: inline-block;
    text-decoration: none;
}

.panels .btns:hover {
    background: #5a5aff;
    color: #fff;
}
