.navigation {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  .navigation &-wrapper {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .navigation &-item {
    padding: 12px 24px;
    color: #98a2aa;
    font-weight: 400;
    background-color: inherit;
    box-sizing: border-box;
    transition: all 0.2s linear;

    .MuiListItemAvatar-root {
      min-width: 30px;
    }
    &:hover {
      background-color: inherit;
      background-image: unset;
      color: white;
    }

    & * {
      font-weight: inherit;
    }

    &-text > span,
    & > svg {
      color: inherit;
      font-weight: inherit;
    }

    &.navigation-item-selected {
      color: white;
      font-weight: 600;

      &:hover {
        background-image: unset;
        background-color: #41323385;
      }

      &::before {
        content: '';
        width: 4px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-image: linear-gradient(#f79c3f, #ef6a4a);
      }
    }
  }

  .navigation-item.navigation-item-parent {
    &.navigation-item-selected {
      color: rgba(0, 0, 0, 0.87);
      background-image: linear-gradient(to right, #ef6a4a, #f79c3f);

      &::before {
        width: 0;
      }
    }
  }

  .navigation-item-signout {
    background-color: #423533ba;

    .MuiTypography-root {
      color: white;
    }
  }

  .navigation-item.navigation-item-child-wrapper {
    background-color: #423533ba;
  }

  .navigation-child-wrapper {
    background-image: linear-gradient(to right, #ef6a4a, #f79c3f);
  }
}

.navigation-divider {
  background-image: linear-gradient(to right, #ef6a4a, #f79c3f);
  height: 2px;
}
