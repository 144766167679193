.side-bar {
  background-color: #08212b;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: all 1s ease-in;

  &.side-bar--out {
    justify-content: center;

    .side-bar-logo {
      margin: unset;
      max-width: 100%;
      height: auto;
    }
  }

  .side-bar-logo {
    margin: 10px;
    width: auto;
    height: 64px;
    transition: all 0.2s linear;
  }

  .side-bar-user {
    width : 200px;
    margin: 0px;
    height: 300px;
  }
}
