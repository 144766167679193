.template {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  overflow: hidden;

  &-left {



  }

  &-right {
    width: 100%;
    transition: all 0.4s ease-in;
    background-color: rgb(227, 226, 226);
  }
}
