fieldset.holder:disabled  {
    cursor: not-allowed;
    opacity: 0.5 !important;
    border: none !important;
}

fieldset.holder:disabled * {
    cursor: not-allowed;
    pointer-events: none;
}

fieldset.holder  {
    border: none !important;
}

