.container {
  margin-right: 1rem;

  &-dark {
    .label {
      background-color: #3e3e3e;
    }
  }
}

.checkbox {
  opacity: 0;
  position: absolute;
}

.label {
  background-color: #333;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  position: relative;
  height: 26px;
  width: 50px;
  transform: scale(1.5);
}

.ball {
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  height: 22px;
  width: 22px;
  transform: translateX(0px);
  transition: transform 0.2s linear;
}

.checkbox:checked + .label .ball {
  transform: translateX(24px);
}

.faMoon,
.faSun {
  color: #5285da;
}
