@use "/src/styles/responsive" as rp;

.section {
  min-height: 100vh;
  display: grid;
  place-content: center;
  row-gap: 1rem;
  color: rgba(255, 255, 255, 0.49);
  font-size: 140%;

  @include rp.responsive(md) {
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
  }
}
