body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

:root {
    --color-primary: #00b956;
}

circle { mix-blend-mode: screen; }
